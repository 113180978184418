$design-orange: #ff7800;
$design-orange-background: #ff9c45;
$code-blue: #00a7f1;
$code-blue-background: #53beed;
$background-gray: #f0f2f5;
$box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
$box-shadow-weak: 0px 2px 4px rgba(0, 0, 0, 0.05);

.App {
  text-align: center;
  font-family: avenir-book, AvenirNext-Regular, AppleSDGothicNeo-Medium,
    Helvetica, Arial, Sans-serif !important;
}

.App-header {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 10px;
  color: white;
  position: absolute;
  z-index: 3;
  position: fixed;
}

.App-header:hover {
  cursor: pointer;
}

.App-header p {
  margin-left: 1em;
  margin-bottom: 0px;
}

header .heart-icon {
  width: 20px;
  margin: 0px 10px;
  opacity: 0.7;
}

.ant-layout-footer .heart-icon {
  width: 15px;
  margin: 0px;
}

.about,
.project {
  padding-bottom: 160px;
}

h1 {
  color: #4f4f4f;
}

section {
  z-index: 4;
  position: relative;
  background-color: white;
}

.section-title {
  border-top: solid 3px rgb(0, 0, 0);
  width: 90%;
  margin: auto;
  text-align: left;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #8c8c8c;
}

.landing-page {
  height: 100vh;
  background-attachment: fixed;
  z-index: 2;

  .landing-page-wrapper {
    position: fixed;
    z-index: 1;
  }
}

.landing-page {
  background-color: black;
  background-image: url("./img/mainback.jpg");
  background-size: cover;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

#landing-page-filter {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 0;
}

.landing-page h1 {
  width: 100%;
  z-index: 1;
  font-size: 3.5rem;
  color: white;
  margin-bottom: 6rem;
}

.project {
  //  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}
.recent-project {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.project-expand-background {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: 0.5;
  top: 0;
  z-index: 100;
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
.segregator {
  width: 20%;
  height: 300px;
  border-left: 8px solid $design-orange;
  border-right: 8px solid $code-blue;
  max-width: 200px;
  margin: auto;
}
.design-project,
.coding-project {
  width: 40%;
  height: 100%;
  //  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 520px;
  transition: 0.5s;
  outline: none;
  position: relative;

  .more-projects-btn {
    border-radius: 50%;
    height: 120px;
    width: 110px;
    position: absolute;
    transition: 0.5s;
    box-shadow: $box-shadow;
    z-index: 100;

    img {
      width: 30px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    p {
      display: none;
    }
  }
  .more-projects-btn:hover {
    cursor: pointer;
    width: 160px;
    height: 160px;

    p {
      display: block;
      width: 100px;
      position: absolute;
      top: 45%;
      transform: translateY(-50%);
      font-weight: 600;
      color: white;
    }

    img {
      width: 25px;
      top: 75%;
    }
  }

  .ant-card-hoverable {
    cursor: default;
  }

  .project-wrapper {
    width: 100%;
    height: 100%;
    transition: 0.5s;

    a {
      color: white;
    }
  }

  .project-wrapper.extra {
    position: absolute;
  }
}

.design-project.expand,
.coding-project.expand {
  z-index: 100;

  .more-projects-btn {
    z-index: 100;
  }

  .ant-card.project-overview-card {
    margin-bottom: 48px;
  }
}

.design-project.expand {
  .project-wrapper {
    background-color: $design-orange-background;
    transform: translateX(100%);
  }

  .project-wrapper.extra {
    left: 0px;
    top: 0px;
    transform: translateX(0);
  }
}

.coding-project.expand {
  .project-wrapper {
    background-color: $code-blue-background;
    transform: translateX(-100%);
  }

  .project-wrapper.extra {
    left: 0px;
    top: 0px;
    transform: translateX(0);
  }
}

.design-project {
  .more-projects-btn {
    left: -65px;
    background-color: $design-orange-background;

    img {
      right: 10px;
    }
  }

  .more-projects-btn:hover {
    background-color: $design-orange;

    p {
      right: 0px;
    }

    img {
      right: 40px;
    }
  }

  .ant-card.project-overview-card {
    margin: 48px 30px 0px auto;

    .hover-cover {
      background-color: #8b4900;
    }
  }

  .project-wrapper.extra {
    transform: translateX(-100%);
  }
}

.coding-project {
  .more-projects-btn {
    right: -65px;
    background-color: $code-blue-background;

    img {
      left: 10px;
    }
  }

  .more-projects-btn:hover {
    background-color: $code-blue;

    p {
      left: 0px;
    }

    img {
      left: 40px;
    }
  }

  .ant-card.project-overview-card {
    margin: 48px auto 0px 30px;

    .hover-cover {
      background-color: #045f87;
    }
  }

  .project-wrapper.extra {
    transform: translateX(100%);
  }
}

.ant-card.project-overview-card {
  width: 63%;
  min-width: 300px;
  max-width: 320px;
  box-shadow: $box-shadow-weak;

  .ant-card-cover {
    border-bottom: 1px solid #e8e8e8;
    position: relative;
    overflow: hidden;

    .design-ribbon,
    .code-ribbon {
      position: absolute;
      top: 20px;
      width: 150px;
      color: white;
      z-index: 1;
      transition: 0.3s;
    }
    .design-ribbon {
      left: -14px;
      background-color: $design-orange;
      transform: rotate(-30deg);
    }
    .code-ribbon {
      right: -14px;
      background-color: $code-blue;
      transform: rotate(30deg);
    }
    img {
      width: 100%;
      opacity: 0.8;
    }

    .hover-cover {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      display: flex;
      color: white;
      flex-direction: column;
      justify-content: center;
      transition: 0.3s;

      p {
        padding: 20px;
        font-weight: 500;
        margin: 0px;
      }

      button {
        border: 3px solid white;
        background-color: #ffffff00;
        border-radius: 50px;
        width: 50%;
        margin: 0px auto;
        padding: 5px;
        font-size: 1.25rem;
        outline: none;
      }

      button:hover {
        cursor: pointer;
      }
    }
  }

  .ant-card-body {
    padding: 12px;
    .ant-card-meta-title {
      font-size: 1.25rem;
    }
  }
}

.ant-card.project-overview-card.ant-card-hoverable:hover {
  transform: translate(0px, -20px);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3);

  .hover-cover {
    opacity: 0.9;
  }
  .design-ribbon,
  .code-ribbon {
    opacity: 0;
  }
}

.contact-methods {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0px;
  margin-bottom: 50px;

  a {
    margin: 20px;
    padding: 10px;
  }

  a:nth-child(1) {
    transform: rotate(-7deg);
  }
  a:nth-child(2) {
    transform: rotate(11deg);
  }
  a:nth-child(3) {
    transform: rotate(-13deg);
  }
  a:nth-child(4) {
    transform: rotate(9deg);
  }
}
.contact-card {
  box-shadow: $box-shadow;
  padding: 20px;
}

.contact-card:hover {
  transform: rotate3d(1, 0, 1, 20deg);
}

.nail {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: brown;
  margin: -10px auto 5px auto;
}
.contact-icon {
  width: 55px;
  height: 55px;
}

.about {
  .about-content {
    background-color: $background-gray;
    padding: 64px 0px;

    p {
      margin: auto;
      font-size: 1.25rem;
      font-weight: 500;
      width: 80%;
      color: black;

      strong {
        font-size: 1.5rem;
      }
    }
  }

  .education-and-industry {
    box-shadow: $box-shadow;
    display: flex;
    margin: 16px 5%;
    justify-content: space-around;

    .education,
    .industry {
      flex: 1 1;
      padding: 0 10px 20px 10px;
    }
    .img-wrapper {
      width: 80px;
      height: 80px;
      margin: auto;
      box-shadow: $box-shadow;
      border-radius: 50%;
      padding: 10px;
      position: relative;
      top: -40px;
    }

    .work-title,
    .education-title {
      color: black;
      font-size: 1.25rem;
      line-height: 1;
      border-bottom: solid 1px;
    }

    .education .img-wrapper {
      background-color: #ffd2a9;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .industry {
      .img-wrapper {
        background-color: #83d6f9;
        img {
          width: 90%;
          height: 90%;
        }
      }
      .work {
        width: 80%;
        margin: auto;
      }

      .work-title {
        float: left;
      }
      .work-detail {
        text-align: right;
      }
    }
  }
}

/*Ant Project Modal*/
.ant-modal.project-detail-modal {
  padding: 0px;
  top: 0;
  background-color: white;
  padding: 8px;

  .back-btn {
    width: fit-content;
    transition: 0.3s;
  }

  .back-btn:hover {
    cursor: pointer;
    color: $design-orange;
    transform: scale(1.5);
  }

  .ant-modal-content {
    height: 100%;
    border-radius: 0px;
    border-bottom: 3px solid $design-orange;
    border-top: 3px solid $design-orange;
  }
  .ant-modal-body {
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .landing-page {
    height: 50vh;
    position: relative;

    h1 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  .section-title {
    margin-bottom: 1rem;
  }

  .about,
  project {
    margin-bottom: 98px;
  }

  .about {
    .about-content {
      padding: 40px 0px;

      p {
        font-size: 1rem;
      }
    }

    .education-and-industry {
      flex-flow: column;
      box-shadow: none;

      .education {
        padding-bottom: 40px;
        margin-bottom: 16px;
        box-shadow: $box-shadow;
      }
      .industry {
        box-shadow: $box-shadow;

        .work {
          width: 100%;
        }
        .work-title {
          margin-right: 8px;
        }
      }
    }
  }

  .recent-project {
    flex-direction: column;

    .segregator {
      display: none;
      /*      border-top: 8px solid #ff7800;
      border-bottom: 8px solid #00a7f1;
      border-left: none;
      border-right: none;
      height: 100px;
      width: 80%;
      margin-top: 48px;*/
    }

    .design-project,
    .coding-project {
      width: 80%;
    }

    .ant-card.project-overview-card {
      width: 100%;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
