$modal-title-font-size: 56px;
$modal-section-title-font-size: 24px;

.modal-wrapper {
  height: 100%;
  overflow: scroll;

  p,
  ul {
    font-size: 16px;
  }

  section {
    margin-bottom: 56px;
  }
}

.overview {
  text-align: center;

  .modal-title {
    font-size: $modal-title-font-size;
    margin-bottom: 18px;
  }

  p {
    margin-bottom: 24px;
  }

  .overview-btn {
    background-color: #00a7f1;
    color: white;
    border: #00a7f1;
  }

  .overview-btn:hover {
    background-color: #53beed;
    color: white;
  }
}

.modal-section {
  h2 {
    font-size: $modal-section-title-font-size;
    margin-bottom: 24px;
  }

  img {
    width: 100%;
  }

  p {
    margin-bottom: 16px;
  }

  .section-content {
    margin-bottom: 48px;
  }

  .flex-container {
    display: flex;
  }

  .example-block {
    margin: 24px 0px 48px 0px;
  }
  /*  .big-img {
    width: 80%;
    display: block;
    margin: auto;
  }*/
}

/*Mommafung*/
.mommafung-modal {
  .persona {
    justify-content: space-around;
    align-items: start;
    img {
      width: 100%;
      max-width: 550px;
      padding: 20px;
      height: auto;
      margin: auto;
    }
  }
}

.homeie-modal {
  .storyboard,
  .prototype,
  .user-research {
    padding: 0px 50px;
    justify-content: space-around;
    align-items: start;
  }
  img {
    width: 100%;
    max-width: 550px;
    padding: 20px;
    height: auto;
    margin: auto;
    flex: 1 1;
  }
  .caption {
    flex: 1 1;
    padding: 20px;
  }
}
